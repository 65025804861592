import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadCrumbs from "../components/breadcrumb"

export default class Apps extends React.Component {

	constructor(props){
		super(props);
		if(typeof window != "undefined"){
			window.location = "https://assistivecards.com/en/games/";
		} 
	}

	render() {
		return null;
	}
}
